import { Box, Button, Card, Container, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import DoneIcon from "@mui/icons-material/Done";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ShopifyButton from "./components/ShopifyButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

const settings_1 = {
  arrows: false,
  dots: true,
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const settings_2 = {
  arrows: false,
  dots: false,
  autoplay: true,
  infinite: true,
  speed: 600,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const settings_3 = {
  arrows: false,
  dots: false,
  autoplay: true,
  infinite: true,
  speed: 700,
  slidesToShow: 3,
  slidesToScroll: 1,
};

const data_bundles = [
  {
    cost_value: 19,
    data_value: "220MB",
  },
  {
    cost_value: 29,
    data_value: "330MB",
  },
  {
    cost_value: 39,
    data_value: "550MB",
  },
  {
    cost_value: 49,
    data_value: "1GB",
  },
  {
    cost_value: 79,
    data_value: "2GB",
  },
  {
    cost_value: 169,
    data_value: "5GB",
  },
  {
    cost_value: 198,
    data_value: "10GB",
  },
  {
    cost_value: 349,
    data_value: "25GB",
  },
];

const data_specials = [
  {
    cost_value: 1,
    data_value: "30 MB",
    expiry_value: "7 Days",
  },
  {
    cost_value: 2,
    data_value: "50 MB",
    expiry_value: "7 Days",
  },
  {
    cost_value: 4,
    data_value: "100 MB",
    expiry_value: "7 Days",
  },
  {
    cost_value: 5,
    data_value: "150 MB",
    expiry_value: "7 Days",
  },
  {
    cost_value: 10,
    data_value: "250 MB",
    expiry_value: "7 Days",
  },
  {
    cost_value: 19,
    data_value: "1 GB",
    expiry_value: "1 Day",
  },
  {
    cost_value: 11,
    data_value: "500 MB",
    expiry_value: "1 Day",
  },
  {
    cost_value: 99,
    data_value: "4 GB",
    expiry_value: "7 Day",
  },
  {
    cost_value: 21,
    data_value: "500 MB",
    expiry_value: "30 Days",
  },
  {
    cost_value: 34,
    data_value: "1 GB",
    expiry_value: "7 Days",
  },
  {
    cost_value: 42,
    data_value: "1 GB",
    expiry_value: "30 Days",
  },
  {
    cost_value: 64,
    data_value: "2 GB",
    expiry_value: "7 Days",
  },
  {
    cost_value: 89,
    data_value: "3 GB",
    expiry_value: "7 Days",
  },
  {
    cost_value: 159,
    data_value: "6 GB",
    expiry_value: "30 Days",
  },
  {
    cost_value: 229,
    data_value: "11 GB",
    expiry_value: "30 Days",
  },
  {
    cost_value: 299,
    data_value: "25 GB",
    expiry_value: "30 Days",
  },
  {
    cost_value: 499,
    data_value: "50 GB",
    expiry_value: "30 Days",
  },
].sort((a, b) => parseFloat(a.expiry_value.split(" ")[0].trim()) - parseFloat(b.expiry_value.split(" ")[0].trim()));

const dataPackages = [
  {
    size: "30 MB Data",
    expiry: "7 Days @ R1.00",
  },
  {
    size: "50 MB Data",
    expiry: "7 Days @ R2.00",
  },
  {
    size: "100 MB Data",
    expiry: "7 Days @ R4.00",
  },
  {
    size: "150 MB Data",
    expiry: "7 Days @ R5.00",
  },
  {
    size: "250 MB Data",
    expiry: "7 Days @ R10.00",
  },
  {
    size: "1 GB Data",
    expiry: "1 Day @ R19.00",
  },
  {
    size: "500 MB Data",
    expiry: "1 Day @ R11.00",
  },
  {
    size: "4 GB Data",
    expiry: "7 Day @ R99.00",
  },
  {
    size: "500 MB Data",
    expiry: "30 Days @ R21.00",
  },
  {
    size: "1 GB Data",
    expiry: "7 Days @ R34.00",
  },
  {
    size: "1 GB Data",
    expiry: "30 Days @ R42.00",
  },
  {
    size: "2 GB Data",
    expiry: "7 Days @ R64.00",
  },
  {
    size: "3 GB Data",
    expiry: "7 Days @ R89.00",
  },
  {
    size: "6 GB Data",
    expiry: "30 Days @ R159.00",
  },
  {
    size: "11 GB Data",
    expiry: "30 Days @ R229.00",
  },
  {
    size: "25 GB Data",
    expiry: "30 Days @ R299.00",
  },
  {
    size: "50 GB Data",
    expiry: "30 Days @ R499.00",
  },
].sort((a, b) => parseFloat(a.expiry.split(" ")[0].trim()) - parseFloat(b.expiry.split(" ")[0].trim()));

// Define the partners logos
const partnersLogos = [
  { src: "./assets/1_for_you.webp", alt: "1 for you" },
  { src: "./assets/blu.png", alt: "blu" },
  { src: "./assets/payment_OTTVOucher.webp", alt: "payment OTTVOucher" },
];

// Define the locations logos
const locationsLogos = [
  { src: "./assets/pep.webp", alt: "pep" },
  { src: "./assets/boxer.webp", alt: "boxer" },
  { src: "./assets/builders.webp", alt: "builders" },
  { src: "./assets/caltex.webp", alt: "caltex" },
  { src: "./assets/checkers.webp", alt: "checkers" },
  { src: "./assets/game.webp", alt: "game" },
  { src: "./assets/ackermans.webp", alt: "ackermans" },
  { src: "./assets/sasol.webp", alt: "sasol" },
  { src: "./assets/spar.webp", alt: "spar" },
  { src: "./assets/shoprite.webp", alt: "shoprite" },
  { src: "./assets/house-and-home.webp", alt: "house and home" },
  { src: "./assets/usave.webp", alt: "usave" },
  { src: "./assets/bp.webp", alt: "bp" },
  { src: "./assets/nedbank.webp", alt: "nedbank" },
  { src: "./assets/clickslogo.png", alt: "clicks" },
  { src: "./assets/freshstoplogo.png", alt: "freshstop" },
];

// Partners component
const PartnersDisplay = () => {
  return (
    <Grid spacing={2} container sx={{ pb: 3 }}>
      {partnersLogos.map((logo, index) => (
        <Grid className="logo-container" item xs={4} direction={"column"} key={index}>
          <img className="straight_logo" src={logo.src} alt={logo.alt} style={{ width: "30%" }} />
        </Grid>
      ))}
    </Grid>
  );
};

const PartnersDisplayMobile = () => {
  return (
    <Slider {...settings_2}>
      {partnersLogos.map((logo, index) => (
        <Box
          key={index}
          sx={{
            pb: 3,
          }}>
          <img className="straight_logo" src={logo.src} alt={logo.alt} style={{ width: "80%" }} />
        </Box>
      ))}
    </Slider>
  );
};

const LocationsDisplay = () => {
  return (
    <Grid spacing={0} container>
      {locationsLogos.map((logo, index) => (
        <Grid
          className="logo-container"
          item
          xs={3}
          direction={"column"}
          key={index}
          sx={{
            p: 0,
            m: 0,
            mb: 3,
          }}>
          <img className="circle_logo" src={logo.src} alt={logo.alt} style={{ width: "30%", height: "auto", objectFit: "contain" }} />
        </Grid>
      ))}
    </Grid>
  );
};

const LocationsDisplayMobile = () => {
  return (
    <Slider {...settings_3}>
      {locationsLogos.map((logo, index) => (
        <Box
          key={index}
          sx={{
            pb: 2,
          }}>
          <img src={logo.src} alt={logo.alt} style={{ width: "80%" }} />
        </Box>
      ))}
    </Slider>
  );
};

const Home = () => {
  const inputRef = useRef();
  const [div_distance, setDivDistance] = useState(0);
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelected] = React.useState(0);
  const handleClose = () => setOpen(false);

  const blackFriday = [
    {
      id: 1,
      title: "6GB + FREE SIM",
      items: [
        "Data NEVER expires!",
        "Best non-expiring data + airtime rates in the country!",
        "You can keep your number or get a new one!",
        "FREE data daily in our App!",
        "megsApp runs on the MTN network!",
      ],
    },
    {
      id: 2,
      title: "25GB + FREE SIM",
      items: [
        "Data NEVER expires!",
        "Best non-expiring data + airtime rates in the country!",
        "You can keep your number or get a new one!",
        "FREE data daily in our App!",
        "megsApp runs on the MTN network!",
      ],
    },
    {
      id: 3,
      title: "55GB + FREE SIM",
      items: [
        "Data NEVER expires!",
        "Best non-expiring data + airtime rates in the country!",
        "You can keep your number or get a new one!",
        "FREE data daily in our App!",
        "megsApp runs on the MTN network!",
      ],
    },
    {
      id: 4,
      title: "ZTE MF935 Portable Router + 25GB data (valid: 30 days)",
      items: [
        "Able to connect with tablet PCs, notebooks, and various types of WiFi devices",
        "High-speed connection with LTE download speeds of up to 150Mbps",
        "User-friendly interface",
        "Removable 2000mAh battery, providing a maximum of 8 hours of battery life",
        "Prepaid SIM card with 25GB data (valid: 30 days)",
      ],
    },
    {
      id: 5,
      title: "eSIM + 3GB Free Data (7-Days)",
      items: [
        "Fully digital, no physical card required.",
        "Please ensure that your device is eSIM compatible before ordering.",
        "Access to all our non-expiring and expiring specials!",
        "Enjoy FREE data every day in our App!",
        "Easily manage your usage and recharge safely within our App.",
        "Experience better nationwide connectivity with our reliable eSIM technology.",
        "Includes 3GB of data valid for 7 days from activation.",
      ],
    },
    {
      id: 6,
      title: "eSIM + 6GB Free Data (7-Days)",
      items: [
        "Fully digital, no physical card required.",
        "Please ensure that your device is eSIM compatible before ordering.",
        "Access to all our non-expiring and expiring specials!",
        "Enjoy FREE data every day in our App!",
        "Easily manage your usage and recharge safely within our App.",
        "Experience better nationwide connectivity with our reliable eSIM technology.",
        "Includes 6GB of data valid for 7 days from activation.",
      ],
    },
    {
      id: 7,
      title: "eSIM + 15GB Free Data (30-Days)",
      items: [
        "Fully digital, no physical card required.",
        "Please ensure that your device is eSIM compatible before ordering.",
        "Access to all our non-expiring and expiring specials!",
        "Enjoy FREE data every day in our App!",
        "Easily manage your usage and recharge safely within our App.",
        "Experience better nationwide connectivity with our reliable eSIM technology.",
        "Includes 15GB of data valid for 30 days from activation.",
      ],
    },
  ];

  const handleOpen = (item) => {
    const selected = blackFriday.filter((i) => i.id === item);
    setSelected(selected[0]);
    setOpen(true);
  };

  useEffect(() => {
    setTimeout(async () => {
      setLoading(false);
    }, 2500);
  }, []);

  useEffect(() => {
    const current = inputRef.current.getBoundingClientRect();
    if (current) {
      setDivDistance(current.left);
    }
  }, [inputRef]);

  return (
    <>
      {loading && (
        <Box
          sx={{
            height: "85vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box>
            <Box className="pin_loader"></Box>
          </Box>
        </Box>
      )}
      <Container className={loading ? "hide_map" : "show_map"} maxWidth={"xl"}>
        {/* HOME START */}
        <Grid container direction={"row"}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            direction={"column"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: { xs: "center", md: "center" },
              pt: { xs: 4, md: "none" },
            }}
            order={{ xs: 3, sm: 1 }}>
            <img className="hero-image" alt="hero" src="./assets/september/eSIM header.png" />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            direction={"column"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            order={{ xs: 1, sm: 2 }}>
            <Box className="hero_container" sx={{ flexGrow: 0 }}>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <img className="app_logo_hero" alt="megsapp logo" src={"./assets/e_sim_wifi.png"} />
              </Box>

              <Typography variant="h3" sx={{ mt: 2, fontFamily: "Open Sans", color: "#fff" }}>
                megsApp eSIM
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontFamily: "VAG Rounded",
                  fontWeight: 400,
                  // fontSize: "5rem",
                  color: "#e41f26",
                }}>
                NOW AVAILABLE
              </Typography>

              <Box
                sx={{
                  margin: "0 auto",
                  mt: 2,
                  display: "flex",
                  width: "20%",
                  height: 4,
                  backgroundColor: "#e41f26",
                }}></Box>

              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                    // fontSize: "1.6rem",
                    color: "#FFF",
                  }}>
                  Say goodbye to physical SIM cards and hello to the future of connectivity!
                </Typography>

                <Box sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.open("https://shop.megsapp.com/pages/megsapp-esim", "_blank")}
                    sx={{ borderRadius: 2, backgroundColor: "#e41f26", color: "#fff", fontFamily: "Open Sans", fontWeight: 700, fontSize: 16, p: 2 }}>
                    Buy Now
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} direction={"column"} sx={{ pt: { xs: 2, md: "none" } }} order={{ xs: 2, sm: 3 }}>
            <Card
              elevation={2}
              sx={{
                my: 0,
                p: { xs: 1, sm: 4 },
                backgroundColor: "#fff",
                borderRadius: "15px",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                position: "relative",
                overflow: "visible",
              }}>
              <Box className={"pulsating-circle"} sx={{ display: { xs: "none", md: "block" } }}></Box>

              <Grid container spacing={2}>
                <Grid
                  onClick={() => handleOpen(1)}
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  xl={3}
                  direction={"column"}
                  sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "center", md: "center" }, pt: { xs: 4, md: "none" } }}>
                  <img src="./assets/september/6GB New.png" className="bf-image" />

                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                    That{" "}
                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                      NEVER
                    </Typography>{" "}
                    Expires!
                  </Typography>
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                    {" "}
                    +{" "}
                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                      FREE
                    </Typography>{" "}
                    SIM card
                  </Typography>
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846", fontSize: 12 }}>
                    for ONLY
                  </Typography>
                  <Typography variant="h1" sx={{ mt: 1, fontFamily: "VAG Rounded", color: "#002846" }}>
                    R149
                  </Typography>

                  <Box sx={{ flexGrow: 0, p: 1, display: "flex", flexDirection: "column", alignSelf: "center" }}>
                    <ShopifyButton id={7869378724030} />
                  </Box>
                </Grid>

                <Grid
                  onClick={() => handleOpen(2)}
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  xl={3}
                  direction={"column"}
                  sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "center", md: "center" }, pt: { xs: 4, md: "none" } }}>
                  <img src="./assets/januworry/Januworry-25Gb.png" className="bf-image" />
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                    That{" "}
                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                      NEVER
                    </Typography>{" "}
                    Expires!
                  </Typography>
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                    {" "}
                    +{" "}
                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                      FREE
                    </Typography>{" "}
                    SIM card
                  </Typography>
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846", fontSize: 12 }}>
                    for ONLY
                  </Typography>
                  <Typography variant="h1" sx={{ mt: 1, fontFamily: "VAG Rounded", color: "#002846" }}>
                    R349
                  </Typography>

                  <Box sx={{ flexGrow: 0, p: 1, display: "flex", flexDirection: "column", alignSelf: "center" }}>
                    <ShopifyButton id={7869378855102} />
                  </Box>
                </Grid>

                <Grid
                  onClick={() => handleOpen(3)}
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  xl={3}
                  direction={"column"}
                  sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "center", md: "center" }, pt: { xs: 4, md: "none" } }}>
                  <img src="./assets/januworry/Januworry-55Gb.png" className="bf-image" />
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                    That{" "}
                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                      NEVER
                    </Typography>{" "}
                    Expires!
                  </Typography>
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                    {" "}
                    +{" "}
                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                      FREE
                    </Typography>{" "}
                    SIM card
                  </Typography>
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846", fontSize: 12 }}>
                    for ONLY
                  </Typography>
                  <Typography variant="h1" sx={{ mt: 1, fontFamily: "VAG Rounded", color: "#002846" }}>
                    R599
                  </Typography>

                  <Box sx={{ flexGrow: 0, p: 1, display: "flex", flexDirection: "column", alignSelf: "center" }}>
                    <ShopifyButton id={7869379018942} />
                  </Box>
                </Grid>

                <Grid
                  onClick={() => handleOpen(4)}
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  xl={3}
                  direction={"column"}
                  sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "center", md: "center" }, pt: { xs: 4, md: "none" } }}>
                  <img src="./assets/router/ZTE_MF935_Portable_Router_25Gb.png" className="bf-image" />
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                    ZTE MF935 Portable Router
                  </Typography>
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                    {" "}
                    + 25GB data Valid for 30-days
                  </Typography>
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846", fontSize: 12 }}>
                    for ONLY
                  </Typography>
                  <Typography variant="h1" sx={{ mt: 1, fontFamily: "VAG Rounded", color: "#002846" }}>
                    R499
                  </Typography>

                  <Box sx={{ flexGrow: 0, p: 1, display: "flex", flexDirection: "column", alignSelf: "center" }}>
                    <ShopifyButton id={8092401270974} />
                  </Box>
                </Grid>

                <Grid
                  onClick={() => handleOpen(6)}
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  xl={3}
                  direction={"column"}
                  sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "center", md: "center" }, pt: { xs: 4, md: "none" } }}>
                  <img src="./assets/july/14.png" className="bf-image" />
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                    eSIM + 6GB{" "}
                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                      FREE
                    </Typography>{" "}
                    Data
                  </Typography>
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                    Valid for 7-Days
                  </Typography>
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846", fontSize: 12 }}>
                    for ONLY
                  </Typography>
                  <Typography variant="h1" sx={{ mt: 1, fontFamily: "VAG Rounded", color: "#002846" }}>
                    R99
                  </Typography>

                  <Box sx={{ flexGrow: 0, p: 1, display: "flex", flexDirection: "column", alignSelf: "center" }}>
                    <ShopifyButton id={8236463095998} />
                  </Box>
                </Grid>

                <Grid
                  onClick={() => handleOpen(7)}
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  xl={3}
                  direction={"column"}
                  sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "center", md: "center" }, pt: { xs: 4, md: "none" } }}>
                  <img src="./assets/july/13.png" className="bf-image" />
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                    eSIM + 15GB{" "}
                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                      FREE
                    </Typography>{" "}
                    Data
                  </Typography>
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                    Valid for 30-Days
                  </Typography>
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846", fontSize: 12 }}>
                    for ONLY
                  </Typography>
                  <Typography variant="h1" sx={{ mt: 1, fontFamily: "VAG Rounded", color: "#002846" }}>
                    R199
                  </Typography>

                  <Box sx={{ flexGrow: 0, p: 1, display: "flex", flexDirection: "column", alignSelf: "center" }}>
                    <ShopifyButton id={8236461195454} />
                  </Box>
                </Grid>

                <Grid
                  onClick={() => handleOpen(5)}
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  xl={3}
                  direction={"column"}
                  sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "center", md: "center" }, pt: { xs: 4, md: "none" } }}>
                  <img src="./assets/september/esim_3gb_7_days.png" className="bf-image" />
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                    eSIM + 3GB{" "}
                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                      FREE
                    </Typography>{" "}
                    Data
                  </Typography>
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                    Valid for 7-Days
                  </Typography>
                  <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846", fontSize: 12 }}>
                    for ONLY
                  </Typography>
                  <Typography variant="h1" sx={{ mt: 1, fontFamily: "VAG Rounded", color: "#002846" }}>
                    R39
                  </Typography>

                  <Box sx={{ flexGrow: 0, p: 1, display: "flex", flexDirection: "column", alignSelf: "center" }}>
                    <ShopifyButton id={8309959327934} />
                  </Box>
                </Grid>


              </Grid>
            </Card>
          </Grid>
        </Grid>
        {/* HOME END */}

        {/* RATES START */}
        <Grid
          item
          xs={12}
          md={6}
          direction={"column"}
          sx={{
            mt: { xs: 0, md: 1 },
            pt: 3,
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: { xs: "flex-start", md: "flex-end" },
            textAlign: { xs: "left", md: "right" },
          }}>
          <Box
            sx={{
              display: { xs: "none", md: "block" },
              position: "absolute",
              height: "35px",
              backgroundColor: "#fff",
              width: "100%",
              right: "-104%",
              top: 20,
              borderRadius: 15,
              p: 1,
            }}>
            <Box className="circle_red"></Box>
          </Box>
          <Box>
            <Typography variant="h1" sx={{ mt: 0, fontFamily: "VAG Rounded", color: "#fff" }}>
              Our Data Bundles
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#fff" }}>
              Our data bundles are simple and super affordable. We only offer 8 data bundles
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: "VAG Rounded", color: "#fff" }}>
              None of our data bundles ever expire.
            </Typography>
          </Box>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            display: { xs: "none", md: "flex" },
            mt: { xs: 0, md: 1 },
            pt: 0,
            alignItems: "stretch",
            justifyContent: "center",
            height: "100%",
          }}>
          {data_bundles.map((bundle, i) => (
            <Grid
              key={i}
              item
              xs={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                p: 2,
                flexGrow: 1,
                height: "unset",
              }}>
              <Box
                sx={{
                  width: "100%",
                  border: 2,
                  borderColor: "#fff",
                  borderRadius: "10px",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  height: "100%",
                }}>
                <Typography
                  variant="h3"
                  sx={{
                    ml: 3,
                    mt: 3,
                    fontFamily: "VAG Rounded",
                    color: "#fff",
                  }}>
                  R {bundle.cost_value}.00
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    ml: 3,
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                    color: "#fff",
                  }}>
                  {bundle.data_value}{" "}
                  <Typography
                    variant="h5"
                    sx={{
                      mt: 2,
                      fontFamily: "Open Sans",
                      fontWeight: 200,
                      color: "#fff",
                    }}
                    component="span">
                    {" "}
                    Data
                  </Typography>
                </Typography>
                {i !== 6 && i !== 7 && (
                  <Typography
                    variant="body1"
                    sx={{
                      ml: 3,
                      mt: 3,
                      verticalAlign: "middle",
                      display: "inline-flex",
                      fontFamily: "VAG Rounded",
                      fontWeight: 400,
                      color: "#fff",
                    }}>
                    <DoneIcon sx={{ mr: 1, color: "#e41f26" }} /> 10% more data rewarded on in-App purchases.
                  </Typography>
                )}
                <Typography
                  variant="body1"
                  sx={{
                    ml: 3,
                    mt: 1,
                    mb: 3,
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "VAG Rounded",
                    fontWeight: 400,
                    color: "#fff",
                  }}>
                  <DoneIcon sx={{ mr: 1, color: "#e41f26" }} /> NEVER Expires
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box
          className="container"
          sx={{
            display: { xs: "block", md: "none" },
            justifyContent: "center",
            alignItems: "center",
            pt: 3,
            pb: 3,
          }}>
          <Slider {...settings_1}>
            {data_bundles.map((bundle, i) => (
              <Box
                key={i}
                direction={"column"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Box
                  sx={{
                    border: 2,
                    borderColor: "#fff",
                    borderRadius: "10px",
                    textAlign: "center",
                    width: "80vw",
                    // p: 2,
                  }}>
                  <Typography variant="h3" sx={{ mt: 3, fontFamily: "VAG Rounded", color: "#fff" }}>
                    R {bundle.cost_value}.00
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                      color: "#fff",
                    }}>
                    {bundle.data_value}{" "}
                    <Typography
                      variant="h5"
                      sx={{
                        mt: 2,
                        fontFamily: "Open Sans",
                        fontWeight: 200,
                        color: "#fff",
                      }}
                      component="span">
                      {" "}
                      Data
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 3,
                      verticalAlign: "middle",
                      display: "inline-flex",
                      fontFamily: "VAG Rounded",
                      fontWeight: 400,
                      color: "#e41f26",
                    }}>
                    <DoneIcon sx={{ mr: 1, color: "#e41f26" }} /> NEVER Expires
                  </Typography>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>

        <Box
          sx={{
            position: "relative",
            mt: { sx: 0, md: 1 },
            textAlign: "left",
          }}>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              alignItems: "flex-end",
              position: "absolute",
              height: "35px",
              backgroundColor: "#fff",
              width: "100%",
              left: "-103%",
              top: 10,
              borderRadius: 15,
              p: 1,
            }}>
            <Box className="circle_red"></Box>
          </Box>
          <Typography variant="h1" sx={{ mt: 2, fontFamily: "VAG Rounded", color: "#fff" }}>
            Our Specials
          </Typography>
          <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#fff" }}>
            Launch specials on expiring data bundles. These are our only data bundles that do expire!
          </Typography>
        </Box>

        <Grid
          container
          spacing={4}
          sx={{
            display: { xs: "none", md: "flex" },
            mt: { xs: 0, md: 1 },
            pt: 0,
            alignItems: "center",
            justifyContent: "center",
          }}>
          {dataPackages.map((dataPackage, index) => (
            <Grid item xs={12} md={3} direction={"column"} sx={{ mt: { xs: 8, md: 0 } }} key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  width: "100%",
                  minHeight: "150px",
                  border: 1,
                  borderColor: "#fff",
                  borderRadius: "10px",
                  backgroundColor: "#fff",
                }}>
                {index === 3 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      top: { md: "-20%" },
                      right: { md: "-8%" },
                      margin: "auto",
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      backgroundColor: "#e41f26",
                    }}>
                    <img className="circle_icons" src="./assets/specials.webp" alt="specials" />
                  </Box>
                )}
                <Typography
                  variant="h3"
                  sx={{
                    fontFamily: "VAG Rounded",
                    fontWeight: 400,
                    color: "#002846",
                  }}>
                  {dataPackage.size}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                    color: "#002846",
                    display: "block",
                  }}>
                  {dataPackage.expiry}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box
          className="container"
          sx={{
            display: { xs: "block", md: "none" },
            justifyContent: "center",
            alignItems: "center",
            py: 3,
            px: 0,
          }}>
          <Slider {...settings_1}>
            {data_specials.map((bundle, i) => (
              <Box
                key={i}
                direction={"column"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  p: 0,
                }}>
                <Box
                  sx={{
                    border: 2,
                    borderColor: "#fff",
                    borderRadius: "10px",
                    textAlign: "center",
                    width: "80vw",
                  }}>
                  <Typography variant="h3" sx={{ mt: 3, fontFamily: "VAG Rounded", color: "#fff" }}>
                    {bundle.data_value} Data
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      mb: 3,
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                      color: "#fff",
                      mx: 3,
                    }}>
                    {bundle.expiry_value}{" "}
                    <Typography
                      variant="h5"
                      sx={{
                        mt: 2,
                        fontFamily: "Open Sans",
                        fontWeight: 200,
                        color: "#fff",
                      }}
                      component="span">
                      {" "}
                      @ R{bundle.cost_value}.00{" "}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-start" },
            alignItems: { xs: "center", md: "flex-start" },
            pt: 3,
            pb: 6,
          }}>
          <Button
            color="secondary"
            size="large"
            to="/get-sim"
            component={RouterLink}
            variant="contained"
            sx={{
              pl: 5,
              pr: 5,
              textTransform: "initial",
              borderRadius: "10px",
            }}
            endIcon={<ArrowRightIcon />}>
            Get a SIM
          </Button>
        </Box>

        <Grid container spacing={2} sx={{ mt: { xs: 0, md: 1 }, position: "relative", pb: 3 }}>
          <Grid
            item
            xs={12}
            md={5}
            direction={"column"}
            sx={{
              display: { xs: "flex", md: "flex" },
              justifyContent: "center",
              alignItems: "flex-start",
            }}>
            <Box ref={inputRef} sx={{ marginLeft: { xs: -div_distance } }}>
              <img
                className="rates-image"
                src={"./assets/sms_rates_2.webp"}
                alt="sms rates"
                style={{
                  left: -div_distance,
                  top: "-40px",
                }}
              />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={7}
            direction={"column"}
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: { xs: "flex-start", md: "flex-end" },
              textAlign: { xs: "left", md: "right" },
              py: 3,
            }}>
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                position: "absolute",
                height: "35px",
                backgroundColor: "#fff",
                width: "100%",
                right: "-107%",
                top: 15,
                borderRadius: 15,
                p: 1,
              }}>
              <Box className="circle_red"></Box>
            </Box>
            <Box>
              <Typography variant="h1" sx={{ mt: 0, fontFamily: "VAG Rounded", color: "#fff" }}>
                Our Voice & SMS Rates
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#fff",
                }}>
                You no longer need to buy voice/SMS bundles. With us, it gets charged directly from your airtime at a flat rate!
              </Typography>
              <Typography variant="body1" sx={{ mt: 2, fontFamily: "VAG Rounded", color: "#fff" }}>
                You can make voice calls or send SMSs to any network at this rate
              </Typography>
              <Typography variant="body1" sx={{ fontFamily: "VAG Rounded", color: "#fff" }}>
                And your Airtime{" "}
                <Typography variant="body1" sx={{ fontFamily: "VAG Rounded", color: "#e41f26" }} component="span">
                  {" "}
                  NEVER
                </Typography>{" "}
                expires!
              </Typography>
            </Box>

            <Grid container spacing={2} sx={{ display: { xs: "none", md: "flex" }, mt: 1 }}>
              <Grid item xs={12} md={6} direction={"column"} sx={{ justifyContent: "center", alignItems: "center" }}>
                <Box
                  sx={{
                    width: "100%",
                    border: 2,
                    borderColor: "#fff",
                    borderRadius: "10px",
                    textAlign: "left",
                  }}>
                  <Typography
                    variant="h4"
                    sx={{
                      ml: 3,
                      mt: 3,
                      fontFamily: "VAG Rounded",
                      color: "#fff",
                    }}>
                    SMS Rates
                  </Typography>
                  <Typography
                    variant="h6"
                    component={"span"}
                    sx={{
                      ml: 3,
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                      color: "#fff",
                    }}>
                    R0.25{" "}
                    <Typography
                      variant="body1"
                      sx={{
                        mt: 2,
                        fontFamily: "Open Sans",
                        fontWeight: 200,
                        color: "#fff",
                      }}
                      component="span">
                      {" "}
                      cents per SMS to any network.
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      ml: 3,
                      mb: 3,
                      verticalAlign: "middle",
                      display: "inline-flex",
                      fontFamily: "VAG Rounded",
                      fontWeight: 400,
                      color: "#e41f26",
                    }}>
                    <DoneIcon sx={{ mr: 1, color: "#e41f26" }} /> NEVER Expires
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                direction={"column"}
                sx={{
                  display: { xs: "none", md: "flex" },
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}>
                <Box
                  sx={{
                    width: "100%",
                    border: 2,
                    borderColor: "#fff",
                    borderRadius: "10px",
                    textAlign: "left",
                  }}>
                  <Typography
                    variant="h4"
                    sx={{
                      ml: 3,
                      mt: 3,
                      fontFamily: "VAG Rounded",
                      color: "#fff",
                    }}>
                    Voice Rates
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      ml: 3,
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                      color: "#fff",
                    }}>
                    R0.69{" "}
                    <Typography
                      variant="body1"
                      sx={{
                        mt: 2,
                        fontFamily: "Open Sans",
                        fontWeight: 200,
                        color: "#fff",
                      }}
                      component="span">
                      {" "}
                      cents per/min billed per/sec to any network.
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      ml: 3,
                      mb: 3,
                      verticalAlign: "middle",
                      display: "inline-flex",
                      fontFamily: "VAG Rounded",
                      fontWeight: 400,
                      color: "#e41f26",
                    }}>
                    <DoneIcon sx={{ mr: 1, color: "#e41f26" }} /> NEVER Expires
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box
          className="container"
          sx={{
            display: { xs: "block", md: "none" },
            justifyContent: "center",
            alignItems: "center",
            pt: 3,
            pb: 3,
          }}>
          <Slider {...settings_1}>
            <Box
              direction={"column"}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Box
                sx={{
                  border: 2,
                  borderColor: "#fff",
                  borderRadius: "10px",
                  textAlign: "left",
                }}>
                <Typography
                  variant="h4"
                  sx={{
                    ml: 3,
                    mt: 3,
                    fontFamily: "VAG Rounded",
                    color: "#fff",
                  }}>
                  SMS Rates
                </Typography>
                <Typography
                  variant="h6"
                  component={"span"}
                  sx={{
                    ml: 3,
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                    color: "#fff",
                  }}>
                  R0.25{" "}
                  <Typography
                    variant="body1"
                    sx={{
                      mt: 2,
                      fontFamily: "Open Sans",
                      fontWeight: 200,
                      color: "#fff",
                    }}
                    component="span">
                    {" "}
                    cents per page across networks.
                  </Typography>
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    ml: 3,
                    mb: 3,
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "VAG Rounded",
                    fontWeight: 400,
                    color: "#fff",
                  }}>
                  <DoneIcon sx={{ mr: 1, color: "#e41f26" }} /> NEVER Expires
                </Typography>
              </Box>
            </Box>
            <Box
              direction={"column"}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Box
                sx={{
                  border: 2,
                  borderColor: "#fff",
                  borderRadius: "10px",
                  textAlign: "left",
                }}>
                <Typography
                  variant="h4"
                  sx={{
                    ml: 3,
                    mt: 3,
                    fontFamily: "VAG Rounded",
                    color: "#fff",
                  }}>
                  Voice Rates
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    ml: 3,
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                    color: "#fff",
                  }}>
                  R0.69{" "}
                  <Typography
                    variant="body1"
                    sx={{
                      mt: 2,
                      fontFamily: "Open Sans",
                      fontWeight: 200,
                      color: "#fff",
                    }}
                    component="span">
                    {" "}
                    cents per minute billed per second.
                  </Typography>
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    ml: 3,
                    mb: 3,
                    verticalAlign: "middle",
                    display: "inline-flex",
                    fontFamily: "VAG Rounded",
                    fontWeight: 400,
                    color: "#fff",
                  }}>
                  <DoneIcon sx={{ mr: 1, color: "#e41f26" }} /> NEVER Expires
                </Typography>
              </Box>
            </Box>
          </Slider>
          <Button
            color="info"
            size="large"
            variant="contained"
            sx={{
              pl: 5,
              pr: 5,
              textTransform: "initial",
              borderRadius: "10px",
              mt: 4,
            }}
            endIcon={<ArrowRightIcon />}>
            Learn More
          </Button>
        </Box>

        <Box sx={{ mt: 1, textAlign: "left", position: "relative" }}>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              alignItems: "flex-end",
              position: "absolute",
              height: "35px",
              backgroundColor: "#fff",
              width: "100%",
              left: "-103%",
              top: 0,
              borderRadius: 15,
              p: 1,
            }}>
            <Box className="circle_red"></Box>
          </Box>
          <Typography variant="h3" sx={{ mt: 2, fontFamily: "VAG Rounded", color: "#fff" }}>
            How to Buy Data & Airtime
          </Typography>
        </Box>

        <Grid container spacing={2} sx={{ display: { xs: "flex" }, mt: { xs: 0, md: 1 }, pt: 6 }}>
          <Grid item xs={12} md={4} direction={"column"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                width: "100%",
                minHeight: "150px",
                border: 2,
                borderColor: "#fff",
                borderRadius: "10px",
              }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: { xs: "-30%", md: "-35%" },
                  left: 0,
                  right: 0,
                  margin: "auto",
                  width: { xs: "80px", md: "100px" },
                  height: { xs: "80px", md: "100px" },
                  borderRadius: "50%",
                  backgroundColor: "#e41f26",
                }}>
                <img className="circle_icon" src="./assets/checkout.png" alt="checkout" />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "VAG Rounded",
                  fontWeight: 400,
                  color: "#fff",
                }}>
                Top up in-App and get 10% more value for the{" "}
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "VAG Rounded",
                    fontWeight: 400,
                    color: "#e41f26",
                  }}
                  component="span">
                  {" "}
                  same price
                </Typography>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} direction={"column"} sx={{ mt: { xs: 8, md: 0 } }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                width: "100%",
                minHeight: "150px",
                border: 2,
                borderColor: "#fff",
                borderRadius: "10px",
              }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: { xs: "-30%", md: "-35%" },
                  left: 0,
                  right: 0,
                  margin: "auto",
                  width: { xs: "80px", md: "100px" },
                  height: { xs: "80px", md: "100px" },
                  borderRadius: "50%",
                  backgroundColor: "#e41f26",
                }}>
                <img className="circle_icon" src="./assets/salesman.png" alt="salesman" />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "VAG Rounded",
                  fontWeight: 400,
                  color: "#fff",
                }}>
                With any of our{" "}
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "VAG Rounded",
                    fontWeight: 400,
                    color: "#e41f26",
                  }}
                  component="span">
                  {" "}
                  megstraders
                </Typography>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} direction={"column"} sx={{ mt: { xs: 8, md: 0 } }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                width: "100%",
                minHeight: "150px",
                border: 2,
                borderColor: "#fff",
                borderRadius: "10px",
              }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: { xs: "-30%", md: "-35%" },
                  left: 0,
                  right: 0,
                  margin: "auto",
                  width: { xs: "80px", md: "100px" },
                  height: { xs: "80px", md: "100px" },
                  borderRadius: "50%",
                  backgroundColor: "#e41f26",
                }}>
                <img className="circle_icon" src="./assets/logistics.png" alt="logistics" />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "VAG Rounded",
                  fontWeight: 400,
                  color: "#fff",
                }}>
                With one of our{" "}
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "VAG Rounded",
                    fontWeight: 400,
                    color: "#e41f26",
                  }}
                  component="span">
                  {" "}
                  distribution partners
                </Typography>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            display: { xs: "none", md: "flex" },
            mt: 3,
            pt: 6,
            flexGrow: 0,
          }}>
          <Grid item xs={12} direction={"column"} sx={{ position: "relative" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                width: "100%",
                minHeight: "150px",
                height: "100%",
                border: 2,
                borderColor: "#fff",
                borderRadius: "10px",
                backgroundColor: "#FFF",
              }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: -50,
                  left: 0,
                  right: 0,
                  margin: "auto",
                  width: "100px",
                  height: "100px",
                  borderRadius: "50px",
                  backgroundColor: "#e41f26",
                }}>
                <img className="circle_icon" src="./assets/partners.png" alt="partners" />
              </Box>
              <Typography
                variant="h4"
                sx={{
                  pb: 3,
                  pt: 9,
                  fontFamily: "VAG Rounded",
                  fontWeight: 400,
                  color: "#002846",
                }}>
                Top-up using these vouchers
              </Typography>

              <PartnersDisplay />
              <Typography
                variant="h4"
                sx={{
                  pb: 4,
                  pt: 9,
                  fontFamily: "VAG Rounded",
                  fontWeight: 400,
                  color: "#002846",
                }}>
                Buy vouchers at these locations
              </Typography>
              <LocationsDisplay />
            </Box>
          </Grid>
        </Grid>

        <Box
          className="container"
          sx={{
            display: { xs: "block", md: "none" },
            justifyContent: "center",
            alignItems: "center",
            pt: 2,
            pb: 1,
            backgroundColor: "#FFF",
            borderRadius: 3,
            my: 3,
          }}>
          <Typography
            variant="h4"
            sx={{
              mb: 1,
              pb: 3,
              fontFamily: "VAG Rounded",
              fontWeight: 400,
              color: "#002846",
              fontSize: "1.5rem",
            }}>
            Top-up using these vouchers
          </Typography>
          <PartnersDisplayMobile />
          <Typography
            variant="h4"
            sx={{
              py: 3,
              fontFamily: "VAG Rounded",
              fontWeight: 400,
              color: "#002846",
              fontSize: "1.5rem",
            }}>
            Buy vouchers at these locations
          </Typography>
          <LocationsDisplayMobile />
        </Box>

        {/* RATES START */}

        <Typography variant="h5" sx={{ mt: 4, fontFamily: "VAG Rounded", color: "#fff" }}>
          Available at more than{" "}
          <Typography variant="h5" sx={{ mt: 2, fontFamily: "VAG Rounded", color: "#e41f26" }} component="span">
            {" "}
            250,000
          </Typography>{" "}
          locations!
        </Typography>

        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={style}>
            <Box className={"pulsating-circle"} sx={{ display: { xs: "none", md: "block" } }}></Box>
            <Typography variant="h6" sx={{ fontFamily: "VAG Rounded", color: "#002846" }}>
              {selectedItem.title}
            </Typography>
            {selectedItem.items &&
              selectedItem.items.map((item, i) => (
                <Box key={i}>
                  <Typography
                    variant="body1"
                    sx={{ mt: 3, verticalAlign: "middle", display: "inline-flex", fontFamily: "VAG Rounded", fontWeight: 400, color: "#002846" }}>
                    <DoneIcon sx={{ mr: 1, color: "#e41f26" }} /> {item}
                  </Typography>
                </Box>
              ))}
          </Box>
        </Modal>
      </Container>
    </>
  );
};
export default Home;
