import { Box, Button, Card, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link as RouterLink } from "react-router-dom";

import ShopifyButton from "./components/ShopifyButton";

const GetSim = () => {
    const [loading, setLoading] = useState(true);
    const [div_distance, setDivDistance] = useState(0);

    const [open, setOpen] = React.useState(false);
    const [selectedItem, setSelected] = React.useState(0);

    const inputRef = useRef();

    const blackFriday = [
        {
            id: 1,
            title: "6GB + FREE SIM",
            items: [
                "Data NEVER expires!",
                "Best non-expiring data + airtime rates in the country!",
                "You can keep your number or get a new one!",
                "FREE data daily in our App!",
                "megsApp runs on the MTN network!",
            ],
        },
        {
            id: 2,
            title: "25GB + FREE SIM",
            items: [
                "Data NEVER expires!",
                "Best non-expiring data + airtime rates in the country!",
                "You can keep your number or get a new one!",
                "FREE data daily in our App!",
                "megsApp runs on the MTN network!",
            ],
        },
        {
            id: 3,
            title: "55GB + FREE SIM",
            items: [
                "Data NEVER expires!",
                "Best non-expiring data + airtime rates in the country!",
                "You can keep your number or get a new one!",
                "FREE data daily in our App!",
                "megsApp runs on the MTN network!",
            ],
        },
        {
            id: 4,
            title: "ZTE MF935 Portable Router + 25GB data (valid: 30 days)",
            items: [
                "Able to connect with tablet PCs, notebooks, and various types of WiFi devices",
                "High-speed connection with LTE download speeds of up to 150Mbps",
                "User-friendly interface",
                "Removable 2000mAh battery, providing a maximum of 8 hours of battery life",
                "Prepaid SIM card with 25GB data (valid: 30 days)",
            ],
        },
        {
            id: 5,
            title: "eSIM + 3GB Free Data (7-Days)",
            items: [
                "Fully digital, no physical card required.",
                "Please ensure that your device is eSIM compatible before ordering.",
                "Access to all our non-expiring and expiring specials!",
                "Enjoy FREE data every day in our App!",
                "Easily manage your usage and recharge safely within our App.",
                "Experience better nationwide connectivity with our reliable eSIM technology.",
                "Includes 3GB of data valid for 7 days from activation.",
            ],
        },
        {
            id: 6,
            title: "eSIM + 6GB Free Data (7-Days)",
            items: [
                "Fully digital, no physical card required.",
                "Please ensure that your device is eSIM compatible before ordering.",
                "Access to all our non-expiring and expiring specials!",
                "Enjoy FREE data every day in our App!",
                "Easily manage your usage and recharge safely within our App.",
                "Experience better nationwide connectivity with our reliable eSIM technology.",
                "Includes 6GB of data valid for 7 days from activation.",
            ],
        },
        {
            id: 7,
            title: "eSIM + 15GB Free Data (30-Days)",
            items: [
                "Fully digital, no physical card required.",
                "Please ensure that your device is eSIM compatible before ordering.",
                "Access to all our non-expiring and expiring specials!",
                "Enjoy FREE data every day in our App!",
                "Easily manage your usage and recharge safely within our App.",
                "Experience better nationwide connectivity with our reliable eSIM technology.",
                "Includes 15GB of data valid for 30 days from activation.",
            ],
        },
    ];

    const handleOpen = (item) => {
        const selected = blackFriday.filter((i) => i.id === item);
        setSelected(selected[0]);
        setOpen(true);
    };

    useEffect(() => {
        setTimeout(async () => {
            setLoading(false);
        }, 2500);
    }, []);

    return (
        <>
            {loading && (
                <Box sx={{ height: "85vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box>
                        <Box className="pin_loader"></Box>
                    </Box>
                </Box>
            )}
            <Container className={loading ? "hide_map" : "show_map"} maxWidth={"xl"}>
                <Grid item xs={12} direction={"column"} sx={{ pt: { xs: 2, md: "none" } }} order={{ xs: 2, sm: 3 }}>
                    <Card
                        elevation={2}
                        sx={{
                            my: 0,
                            p: { xs: 1, sm: 4 },
                            backgroundColor: "#fff",
                            borderRadius: "15px",
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            position: "relative",
                            overflow: "visible",
                        }}>
                        <Box className={"pulsating-circle"} sx={{ display: { xs: "none", md: "block" } }}></Box>
                        <Grid container spacing={2}>
                            <Grid
                                onClick={() => handleOpen(1)}
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                xl={3}
                                direction={"column"}
                                sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "center", md: "center" }, pt: { xs: 4, md: "none" } }}>
                                <img src="./assets/september/6GB New.png" className="bf-image" />
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                                    That{" "}
                                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                                        NEVER
                                    </Typography>{" "}
                                    Expires!
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                                    {" "}
                                    +{" "}
                                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                                        FREE
                                    </Typography>{" "}
                                    SIM card
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846", fontSize: 12 }}>
                                    for ONLY
                                </Typography>
                                <Typography variant="h1" sx={{ mt: 1, fontFamily: "VAG Rounded", color: "#002846" }}>
                                    R149
                                </Typography>

                                <Box sx={{ flexGrow: 0, p: 1, display: "flex", flexDirection: "column", alignSelf: "center" }}>
                                    <ShopifyButton id={7869378724030} />
                                </Box>
                            </Grid>

                            <Grid
                                onClick={() => handleOpen(2)}
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                xl={3}
                                direction={"column"}
                                sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "center", md: "center" }, pt: { xs: 4, md: "none" } }}>
                                <img src="./assets/januworry/Januworry-25Gb.png" className="bf-image" />
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                                    That{" "}
                                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                                        NEVER
                                    </Typography>{" "}
                                    Expires!
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                                    {" "}
                                    +{" "}
                                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                                        FREE
                                    </Typography>{" "}
                                    SIM card
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846", fontSize: 12 }}>
                                    for ONLY
                                </Typography>
                                <Typography variant="h1" sx={{ mt: 1, fontFamily: "VAG Rounded", color: "#002846" }}>
                                    R349
                                </Typography>

                                <Box sx={{ flexGrow: 0, p: 1, display: "flex", flexDirection: "column", alignSelf: "center" }}>
                                    <ShopifyButton id={7869378855102} />
                                </Box>
                            </Grid>

                            <Grid
                                onClick={() => handleOpen(3)}
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                xl={3}
                                direction={"column"}
                                sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "center", md: "center" }, pt: { xs: 4, md: "none" } }}>
                                <img src="./assets/januworry/Januworry-55Gb.png" className="bf-image" />
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                                    That{" "}
                                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                                        NEVER
                                    </Typography>{" "}
                                    Expires!
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                                    {" "}
                                    +{" "}
                                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                                        FREE
                                    </Typography>{" "}
                                    SIM card
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846", fontSize: 12 }}>
                                    for ONLY
                                </Typography>
                                <Typography variant="h1" sx={{ mt: 1, fontFamily: "VAG Rounded", color: "#002846" }}>
                                    R599
                                </Typography>

                                <Box sx={{ flexGrow: 0, p: 1, display: "flex", flexDirection: "column", alignSelf: "center" }}>
                                    <ShopifyButton id={7869379018942} />
                                </Box>
                            </Grid>

                            <Grid
                                onClick={() => handleOpen(4)}
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                xl={3}
                                direction={"column"}
                                sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "center", md: "center" }, pt: { xs: 4, md: "none" } }}>
                                <img src="./assets/router/ZTE_MF935_Portable_Router_25Gb.png" className="bf-image" />
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                                    ZTE MF935 Portable Router
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                                    {" "}
                                    + 25GB data Valid for 30-days
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846", fontSize: 12 }}>
                                    for ONLY
                                </Typography>
                                <Typography variant="h1" sx={{ mt: 1, fontFamily: "VAG Rounded", color: "#002846" }}>
                                    R499
                                </Typography>

                                <Box sx={{ flexGrow: 0, p: 1, display: "flex", flexDirection: "column", alignSelf: "center" }}>
                                    <ShopifyButton id={8092401270974} />
                                </Box>
                            </Grid>

                            <Grid
                                onClick={() => handleOpen(6)}
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                xl={3}
                                direction={"column"}
                                sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "center", md: "center" }, pt: { xs: 4, md: "none" } }}>
                                <img src="./assets/july/14.png" className="bf-image" />
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                                    eSIM + 6GB{" "}
                                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                                        FREE
                                    </Typography>{" "}
                                    Data
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                                    Valid for 7-Days
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846", fontSize: 12 }}>
                                    for ONLY
                                </Typography>
                                <Typography variant="h1" sx={{ mt: 1, fontFamily: "VAG Rounded", color: "#002846" }}>
                                    R99
                                </Typography>

                                <Box sx={{ flexGrow: 0, p: 1, display: "flex", flexDirection: "column", alignSelf: "center" }}>
                                    <ShopifyButton id={8236463095998} />
                                </Box>
                            </Grid>

                            <Grid
                                onClick={() => handleOpen(7)}
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                xl={3}
                                direction={"column"}
                                sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "center", md: "center" }, pt: { xs: 4, md: "none" } }}>
                                <img src="./assets/july/13.png" className="bf-image" />
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                                    eSIM + 15GB{" "}
                                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                                        FREE
                                    </Typography>{" "}
                                    Data
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                                    Valid for 30-Days
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846", fontSize: 12 }}>
                                    for ONLY
                                </Typography>
                                <Typography variant="h1" sx={{ mt: 1, fontFamily: "VAG Rounded", color: "#002846" }}>
                                    R199
                                </Typography>

                                <Box sx={{ flexGrow: 0, p: 1, display: "flex", flexDirection: "column", alignSelf: "center" }}>
                                    <ShopifyButton id={8236461195454} />
                                </Box>
                            </Grid>

                            <Grid
                                onClick={() => handleOpen(5)}
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                xl={3}
                                direction={"column"}
                                sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "center", md: "center" }, pt: { xs: 4, md: "none" } }}>
                                <img src="./assets/september/esim_3gb_7_days.png" className="bf-image" />
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                                    eSIM + 3GB{" "}
                                    <Typography variant="body1" sx={{ display: "inline-flex", fontFamily: "Open Sans", fontWeight: 400, color: "#e41f26" }}>
                                        FREE
                                    </Typography>{" "}
                                    Data
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846" }}>
                                    Valid for 7-Days
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#002846", fontSize: 12 }}>
                                    for ONLY
                                </Typography>
                                <Typography variant="h1" sx={{ mt: 1, fontFamily: "VAG Rounded", color: "#002846" }}>
                                    R39
                                </Typography>

                                <Box sx={{ flexGrow: 0, p: 1, display: "flex", flexDirection: "column", alignSelf: "center" }}>
                                    <ShopifyButton id={8309959327934} />
                                </Box>
                            </Grid>


                        </Grid>
                    </Card>
                </Grid>

                <Grid item xs={12} direction={"column"} sx={{ pt: { xs: 2, md: "none" } }} order={{ xs: 2, sm: 3 }}>
                    <Card
                        elevation={1}
                        sx={{
                            mt: 2,
                            p: { xs: 1, sm: 4 },
                            backgroundColor: "#fff",
                            borderRadius: "15px",
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            position: "relative",
                            overflow: "visible",
                        }}>
                        <Box className={"pulsating-circle"} sx={{ display: { xs: "none", md: "block" } }}></Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                p: 2,
                                pl: { xs: 1, sm: 5 },
                                pr: { xs: 1, sm: 5 },
                                display: "flex",
                                flexDirection: "column",
                                alignItems: { xs: "center", md: "flex-start" },
                            }}>
                            <Typography variant="h3" sx={{ fontFamily: "VAG Rounded", color: "#002846" }}>
                                How to
                                <Typography variant="h3" sx={{ fontFamily: "VAG Rounded", color: "#e41f26" }} component="span">
                                    {" "}
                                    Self RICA?
                                </Typography>
                            </Typography>
                            <Typography variant="body1" sx={{ fontFamily: "Open Sans", fontWeight: 400, color: "#4c4f54" }}>
                                Follow the instructions provided on our self RICA page
                            </Typography>
                        </Box>

                        <Box sx={{ flexGrow: 0, p: 2, pl: 5, pr: 5, display: "flex", flexDirection: "column", alignSelf: "center" }}>
                            <Button
                                color="primary"
                                size="large"
                                variant="contained"
                                to="/self-rica"
                                component={RouterLink}
                                sx={{ pl: 5, pr: 5, textTransform: "initial", borderRadius: "10px", width: { xs: "100%" } }}
                                endIcon={<ArrowRightIcon />}>
                                Self RICA
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            </Container>
        </>
    );
};
export default GetSim;
